import * as Cookie from '@integrabeauty/cookie';
import '@integrabeauty/klaviyo-types';

// This code uses the deprecated _learnq method for capturing data because Klaviyo's new code has
// bugs and also tries to leverage JavaScript Proxy objects that do not work well in older browsers.
// Once Klaviyo becomes more stable or more compatible with the browsers we serve, we will switch
// over to the updated SDK. Until then, we are forced to use the deprecated calls.

// This uses try/catch around calls to _learnq.push because we have observed some unexpected errors
// in this function. The internals of the function are obfuscated but it seems like it is making
// some incorrect assumptions and does not work in certain browsers in certain conditions. Event
// listeners should not throw, in general.

// To test, see https://help.klaviyo.com/hc/en-us/articles/4425956184731

const listenerMap: Record<string, (event: any)=> void> = {
  'attentive-lead-captured': onAttentiveLeadCaptured,
  'bis-form-submitted': onBISFormSubmitted,
  'cart-updated': onCartUpdated,
  'hair-quiz-completed': onHairQuizCompleted,
  'marketing-subscribed': onMarketingSubscribed,
  'product-bundle-viewed': onProductBundleViewed,
  'product-variant-viewed': onProductVariantViewed,
  'typeform-submitted': onTypeformSubmitted,
  'visitor-authenticated': onVisitorAuthenticated,
  'yotpo-question-created': onYotpoQuestionCreated,
  'yotpo-review-submitted': onYotpoReviewSubmitted
};

/**
 * Return whether the visitor consents to tracking for marketing purposes.
 *
 * This does not check the DoNotTrack property because that was deprecated.
 *
 * @param marketHandle the value corresponding to liquid's localization.market.handle
 * @returns whether the visitor consents to tracking
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/globalPrivacyControl
 */
function hasConsent(marketHandle: string) {
  let cookie;
  try {
    cookie = Cookie.read('OptanonConsent');
  } catch (error) {}

  const groups: Record<string, '0' | '1' | undefined> = {};
  if (cookie) {
    let params;
    try {
      params = new URLSearchParams(cookie);
    } catch (error) {}

    // The groups param contains a comma-separated list of tuples. Each tuple consists of a group
    // identifier and a true/false flag separated by a colon. For example, C0001:1,C0002:1.

    const tuples = params?.get('groups')?.split(',') || [];
    for (const tuple of tuples) {
      const [name, flag] = tuple.split(':');
      if (flag === '1' || flag === '0') {
        groups[name] = flag;
      }
    }
  }

  const ONETRUST_TARGETING_GROUP = 'C0004';
  const value = groups[ONETRUST_TARGETING_GROUP];

  // The user granted consent.
  if (value === '1') {
    return true;
  }

  // The user denied consent.
  if (value === '0') {
    return false;
  }

  // the explicit comparison here is paranoia over non-standard implementations that might use an
  // unexpected value type for this non-standard experimental property.
  if (navigator.globalPrivacyControl === true) {
    return false;
  }

  // When OneTrust returns a non-boolean we are not aware of the consent so we must choose a default
  // value. Here we fallback to considering the Shopify market. Visitors are automatically assigned
  // to a specific market based on their IP address.
  //
  // * For UK market visitors, users are opted out by default, we want to return false.
  // * For all other visitors, users are opted in by default, we want to return true.

  return marketHandle !== 'gb';
}

/**
 * A dummy no-operation function
 */
function noop() {}

function onVisitorAuthenticated(event: WindowEventMap['visitor-authenticated']) {
  const input: Record<string, any> = {};

  if (event.detail.email) {
    input['$email'] = event.detail.email.toLowerCase();
  }

  if (event.detail.phone) {
    input['$phone_number'] = event.detail.phone;
  } else if (event.detail.default_address?.phone) {
    input['$phone_number'] = event.detail.default_address.phone;
  }

  if (event.detail.first_name) {
    input['$first_name'] = event.detail.first_name;
  } else if (event.detail.default_address?.first_name) {
    input['$first_name'] = event.detail.default_address.first_name;
  }

  if (event.detail.last_name) {
    input['$last_name'] = event.detail.last_name;
  } else if (event.detail.default_address?.last_name) {
    input['$last_name'] = event.detail.default_address.last_name;
  }

  if (Object.keys(input).length > 0) {
    try {
      klaviyo.push(['identify', input, noop]);
    } catch (error) {
      console.warn(error);
    }
  }
}

function onBISFormSubmitted(event: WindowEventMap['bis-form-submitted']) {
  if (event.detail.success && event.detail.email) {
    const input: Record<string, any> = {
      $email: event.detail.email.toLowerCase()
    };

    try {
      klaviyo.push(['identify', input, noop]);
    } catch (error) {
      console.warn(error);
    }
  }
}

function onTypeformSubmitted(event: WindowEventMap['typeform-submitted']) {
  if (!event.detail.error_message && event.detail.email) {
    const input: Record<string, any> = {
      $email: event.detail.email.toLowerCase()
    };

    try {
      klaviyo.push(['identify', input, noop]);
    } catch (error) {
      console.warn(error);
    }
  }
}

function onAttentiveLeadCaptured(event: WindowEventMap['attentive-lead-captured']) {
  const input: Record<string, any> = {};

  if (event.detail.email) {
    input['$email'] = event.detail.email.toLowerCase();
  }

  if (event.detail.phone) {
    input['$phone_number'] = event.detail.phone;
  }

  if (Object.keys(input).length > 0) {
    try {
      klaviyo.push(['identify', input, noop]);
    } catch (error) {
      console.warn(error);
    }
  }
}

function onMarketingSubscribed(event: WindowEventMap['marketing-subscribed']) {
  if (!event.detail.success) {
    return;
  }

  const input: Record<string, any> = {};

  if (event.detail.first_name) {
    input['$first_name'] = event.detail.first_name;
  }

  if (event.detail.last_name) {
    input['$last_name'] = event.detail.last_name;
  }

  if (event.detail.email) {
    input['$email'] = event.detail.email.toLowerCase();
  }

  if (event.detail.phone) {
    input['$phone_number'] = event.detail.phone;
  }

  if (Object.keys(input).length > 0) {
    try {
      klaviyo.push(['identify', input, noop]);
    } catch (error) {
      console.warn(error);
    }
  }
}

function onYotpoReviewSubmitted(event: WindowEventMap['yotpo-review-submitted']) {
  if (event.detail.error_message) {
    return;
  }

  const input: Record<string, any> = {};

  if (event.detail.email) {
    input['$email'] = event.detail.email.toLowerCase();
  }

  if (event.detail.first_name) {
    input['$first_name'] = event.detail.first_name;
  }

  if (event.detail.last_name) {
    input['$last_name'] = event.detail.last_name;
  }

  if (Object.keys(input).length > 0) {
    try {
      klaviyo.push(['identify', input, noop]);
    } catch (error) {
      console.warn(error);
    }
  }
}

function onYotpoQuestionCreated(event: WindowEventMap['yotpo-question-created']) {
  if (event.detail.email) {
    const input: Record<string, any> = {
      $email: event.detail.email.toLowerCase()
    };

    try {
      klaviyo.push(['identify', input, noop]);
    } catch (error) {
      console.warn(error);
    }
  }
}

/**
 * When sending these events, we have our own properties, but marketing requested we also try to
 * send properties in the same way that Klaviyo tracks similar events and properties, so we are
 * now sending various properties redundantly to try to appease marketing. Marketing has to
 * reference these properties in the Klaviyo campaign editor when choosing how to place product
 * information into messages and finds it easier to have similarly named properties.
 *
 * This is currently not relaying CompareAtPrice. Our upstream event dispatching logic for emitting
 * compare at price in product viewed events is not working.
 *
 * @todo fix upstream sending of compare at price, then also add compare at price
 *
 * @see https://developers.klaviyo.com/en/docs/custom_event_tracking
 */
function onProductVariantViewed(event: WindowEventMap['product-variant-viewed']) {
  // we are going to handle these views in another way
  if (event.detail.bundling_type !== 'single') {
    return;
  }

  // Marketing wants to see the overrides for product titles in Klaviyo.
  const productTitle = event.detail.product_title_override ?
    event.detail.product_title_override :
    event.detail.product_title;

  const variantTitle = event.detail.variant_title === 'Default Title' ?
    undefined :
    event.detail.variant_title;

  // It is not clear, but based on the idea that klaviyo probably derives "Title" from line item
  // title, and that Shopify line items have a title that is derived from a combination of product
  // title and variant title, then we can construct a similar title. In doing so, we have to be
  // careful of how some simple bundles are configured, in that some variant titles have the
  // bundle product title in the variant title, and some do not.

  let itemTitle: string;
  if (variantTitle) {
    if (variantTitle.includes(productTitle)) {
      // The simple bundle product title or product override is already in the name of the variant.
      // The full title is just the variant title.
      itemTitle = variantTitle;
    } else {
      itemTitle = `${productTitle} - ${variantTitle}`;
    }
  } else {
    itemTitle = productTitle;
  }

  try {
    klaviyo.track(
      'Product Viewed',
      {
        '$event_id': `product-viewed-${event.detail.variant_id}-${Date.now() / 1000}`,
        product_id: event.detail.product_id,
        product_title: event.detail.product_title,
        product_title_override: event.detail.product_title_override,
        sku: event.detail.sku,
        variant_id: event.detail.variant_id,
        variant_title: variantTitle,
        Categories: [event.detail.type || ''],
        ImageURL: toCanonicalUrl(event.detail.image_url),
        ItemId: event.detail.product_id,
        Price: (event.detail.price / 100) || undefined,
        ProductName: productTitle,
        ProductId: event.detail.product_id,
        SKU: event.detail.sku,
        Title: itemTitle,
        URL: toCanonicalUrl(event.detail.url)
      },
      noop
    );
  } catch (error) {
    console.warn(error);
  }

  try {
    klaviyo.push([
      'trackViewedItem',
      {
        '$event_id': `trackViewedItem-${event.detail.variant_id}-${Date.now() / 1000}`,
        product_id: event.detail.product_id,
        product_title: event.detail.product_title,
        product_title_override: event.detail.product_title_override,
        sku: event.detail.sku,
        variant_id: event.detail.variant_id,
        variant_title: variantTitle,
        Categories: [event.detail.type || ''],
        ItemId: event.detail.product_id,
        ImageURL: toCanonicalUrl(event.detail.image_url),
        Metadata: {
          Brand: 'L\'ange',
          CompareAtPrice: event.detail.compare_at_price / 100,
          Price: (event.detail.price / 100) || undefined
        },
        Price: (event.detail.price / 100) || undefined,
        ProductName: productTitle,
        ProductId: event.detail.product_id,
        SKU: event.detail.sku,
        Title: itemTitle,
        URL: toCanonicalUrl(event.detail.url)
      },
      noop
    ]);
  } catch (error) {
    console.warn(error);
  }
}

function onProductBundleViewed(event: WindowEventMap['product-bundle-viewed']) {
  try {
    klaviyo.push([
      'track',
      'Product Viewed',
      {
        '$event_id': `product-viewed-${event.detail.variant_id}-${Date.now() / 1000}`,
        product_id: event.detail.product_id,
        product_title: event.detail.product_title,
        product_title_override: event.detail.product_title_override,
        sku: event.detail.sku,
        variant_id: event.detail.variant_id,
        variant_title: event.detail.variant_title,
        CompareAtPrice: event.detail.compare_at_price / 100,
        Categories: [event.detail.type || ''],
        ImageURL: toCanonicalUrl(event.detail.image_url),
        ItemId: event.detail.product_id,
        Price: (event.detail.price / 100) || undefined,
        ProductName: event.detail.product_title,
        ProductId: event.detail.product_id,
        SKU: event.detail.sku,
        Title: event.detail.product_title,
        URL: toCanonicalUrl(event.detail.url)
      },
      noop
    ]);
  } catch (error) {
    console.warn(error);
  }
}

function isValidEmail(email: string) {
  // eslint-disable-next-line @stylistic/max-len
  return /^[\w!#\$%&\'\*\+\/\=\?\^`\{\|\}~\-]+(:?\.[\w!#\$%&\'\*\+\/\=\?\^`\{\|\}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/i.test(email);
}

function onHairQuizCompleted(event: WindowEventMap['hair-quiz-completed']) {
  const email = event.detail.email;
  if (isValidEmail(email)) {
    const input: Record<string, any> = {
      $email: email.toLowerCase()
    };

    try {
      klaviyo.push(['identify', input, noop]);
    } catch (error) {
      console.warn(error);
    }
  }

  try {
    klaviyo.push([
      'track',
      'Hair Quiz Completed',
      {
        recommendation_count: event.detail.number_of_recommendations
      },
      noop
    ]);
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Resolves a url without throwing an error. Tolerates bad input.
 *
 * @todo we need to support multiple domains, so we should not hard code this domain here, we should
 * use location.href or something like that instead
 */
function toCanonicalUrl(path: string) {
  if (path) {
    let url;

    // Resolve the path. If the path is already absolute, it will be returned as is.
    try {
      url = new URL(path, 'https://langehair.com');
    } catch (error) {
      console.warn(error);
    }

    return url?.href;
  }
}

/**
 * It seems like klaviyo implicitly generates an event id using a unix epoch in seconds, so if we
 * send multiple events within the same second, we end up capturing only a single event, because the
 * other events with the same id are discarded. To circumvent that issue we explicitly set the
 * undocumented $event_id property which was gleaned from a code example in the docs.
 *
 * This is sending some properties redundantly and using property names that are explicitly against
 * our coding conventions. We want to send some properties that have names that are similar to the
 * properties that Klaviyo automatically collects on its own through its own events. This simplifies
 * the job of marketing employees who are finding it problematic to align Klaviyo's event property
 * names with our preferred custom property names.
 *
 * @see https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#active-on-site-tracking-snippet
 * @see https://developers.klaviyo.com/en/docs/custom_event_tracking
 */
function onCartUpdated(event: WindowEventMap['cart-updated']) {
  for (const variant of event.detail.variants_added) {
    try {
      klaviyo.push([
        'track',
        'Product Added',
        {
          '$event_id': `product-added-${variant.variant_id}-${Date.now()}`,
          '$value': variant.price / 100,
          product_id: variant.product_id,
          product_title: variant.product_title,
          quantity: variant.quantity,
          sku: variant.sku,
          variant_id: variant.variant_id,
          variant_title: variant.variant_title === 'Default Title' ?
            undefined :
            variant.variant_title,
          Categories: [variant.product_type],
          ItemId: variant.product_id?.toString(),
          ImageURL: toCanonicalUrl(variant.image_url),
          Price: (variant.price / 100) || undefined,
          ProductName: variant.product_title,
          ProductId: variant.product_id,
          SKU: variant.sku,
          Title: variant.product_title,
          URL: toCanonicalUrl(variant.url)
        },
        noop
      ]);
    } catch (error) {
      console.warn(error);
    }
  }
}

function onDOMContentLoaded(_event: Event) {
  window.klaviyo = window.klaviyo || <typeof klaviyo><unknown>[];

  // Unhook the loader listener
  for (const eventType in listenerMap) {
    removeEventListener(eventType, klaviyo_event_listener);
  }

  // Replay prior events. We need an explicit try/catch per call because we are manually and
  // imperatively invoking the event listener function.

  for (const event of klaviyo_event_queue) {
    try {
      listenerMap[event?.type]?.(event);
    } catch (error) {
      console.warn(error);
    }
  }

  // Clear the queue for garbage collection and idempotency
  klaviyo_event_queue = [];

  // Hook up the new listeners
  for (const type in listenerMap) {
    addEventListener(type, listenerMap[type]);
  }
}

function shouldInit() {
  let isTop;
  try {
    isTop = top === window;
  } catch (error) {}

  if (!isTop) {
    return false;
  }

  return true;
}

if (shouldInit() && hasConsent(klaviyo_market_handle)) {
  if (document.readyState === 'complete' || document.readyState === 'interactive' ||
    !window.klaviyo) {
    setTimeout(onDOMContentLoaded, 1000);
  } else {
    addEventListener('DOMContentLoaded', onDOMContentLoaded);
  }
}
